/* eslint-disable camelcase */
import React from 'react';
import { Card, Image, Label } from 'semantic-ui-react';
// import Img from 'gatsby-image'
import { Link } from 'gatsby';
import map from 'lodash/map';

const mapPackagesToItems = packages =>
  map(
    packages,
    ({ id, ref, name, default_photo, quantity_min, quantity_max, price }) => {
      return {
        as: Link,
        to: `/pacote/${ref}`,
        childKey: id,
        header: name,
        color: 'red',
        image: (
          <Image>
            {price ? (
              <Label color="red" ribbon style={{ zIndex: '1' }}>
                Novo!
              </Label>
            ) : null}
            <img
              src={default_photo}
              alt={name}
              style={{
                background: '#fafafa',
              }}
            />
          </Image>
        ),
        meta: (
          <Card.Meta>
            Valor Uni.: {price} | Qtd min: {quantity_min} | Qtd max:{' '}
            {quantity_max}
          </Card.Meta>
        ),
      };
    }
  );

export default ({ packages }) => {
  return (
    <Card.Group
      items={mapPackagesToItems(packages)}
      itemsPerRow={2}
      stackable
    />
  );
};
