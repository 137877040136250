import React from 'react';
import _get from 'lodash/get';
import { Image, Header } from 'semantic-ui-react';
import PackageList from '../components/PackageList';
import SEO from '../components/SEO';
import logo from '../images/ill-short-dark.svg';
import Layout from '../components/Layout';
import { graphql } from 'gatsby';

const IndexPage = ({ data, location }) => {
  const siteTitle = _get(data, 'site.siteMetadata.title');
  const packages = _get(data, 'laravel.packages.data');

  return (
    <Layout location={location}>
      <SEO title={siteTitle} />
      <Header
        as="h3"
        icon
        textAlign="center"
        style={{
          marginBottom: '2em',
        }}
      >
        <Header.Content
          style={{
            width: '60%',
            margin: '0 auto',
          }}
        >
          <Image src={logo} alt="logo" />
        </Header.Content>
      </Header>

      <PackageList packages={packages} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query IndexPageQuery {
    site {
      siteMetadata {
        title
      }
    }
    laravel {
      packages(count: 30) {
        data {
          id
          ref
          name
          default_photo
          quantity_min
          quantity_max
          price
        }
      }
    }
  }
`;

export default IndexPage;
